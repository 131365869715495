<template>
  <div class="new-detail">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>消息详情</span>
      <span></span>
    </header>
    <div class="article">
      <div class="title">{{ title }}</div>
      <div class="author">
        <span class="col-xs-6">
          <font-awesome-icon icon="user" /> 发送人：{{ author }}</span
        >
        <span class="col-xs-6 ">
          <span class="pull-right"
            ><font-awesome-icon icon="clock" spin /> {{ time }}</span
          >
        </span>
      </div>
      <hr />
      <div class="content">{{ content }}</div>
      <hr />
      <div class="desc">
        <span class=" pull-right">
          关联链接：<a :href="url">{{ url }}</a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { getMessInfoSetRead } from '@/api/website.js';
import { formatDate } from '@/utils/index.js';
function getValidTime(value) {
  if (value == null || value == '') return '';
  var myDate = new Date(value.match(/\d+/)[0] * 1);
  return formatDate(myDate);
}
export default {
  data() {
    return {
      title: '',
      author: '',
      time: '',
      desc: '',
      url: '',
      content: '',
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {},
  methods: {
    loadData() {
      var msid = this.$route.query.id;
      getMessInfoSetRead(msid).then(res => {
        if (res.Result == true) {
          let msdata = res.DataDict['data'];
          this.title = msdata.sTitle;
          this.author = msdata.senderName;
          this.content = msdata.sBody;

          this.url = msdata.sUrl;
          this.time = getValidTime(msdata.dSendTime);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/_var';
.article {
  margin: rem(20px);
  margin-top: rem(100px);
  background: #fff;
  max-width: 100%;
  padding: rem(20px);
  .title {
    font-size: rem(40px);
    text-align: left;
    padding: rem(15px);
  }
  .img img {
    width: 100%;
    height: rem(260px);
    border: 1px solid #efefef;
    border-radius: 10px;
  }
  .content img {
    max-width: 100%;
  }
  .author {
    font-size: rem(18px);
    padding: rem(15px);
  }
  .desc {
    text-align: right;
    height: rem(40px);
  }
}
</style>
